<template>
  <v-app>
    <app-layout v-if="authState"></app-layout>
    <auth-form v-if="!authState"></auth-form>
    <!-- <payment-payu v-if="paid === 'unpaid'"></payment-payu> -->
    <v-row v-if="authState && paid === 'loading'" justify="center" align="center">
      <v-col cols="12" justify="center" align="center">
        <v-progress-circular size="50" indeterminate color="#01203f"></v-progress-circular>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import Layout from './views/Layout'
import AuthForm from './views/Auth'
//import PaymentPayu from '@/components/PaymentPayu.vue'

export default {
  components: {
    AppLayout: Layout,
    AuthForm
    //PaymentPayu
  },
  data () {
    return {
    }
  },
  computed: {
    authState () {
      return this.$store.getters.isLoggedIn 
    },

    paid () {
      var payd = this.$store.getters.loadedPaymentDetails
      var d = new Date()
      var validityCheck = +payd.expires - d.getTime()
      if (payd.loading) {
        return 'loading'
      }
      if(payd.status == 'paid' && validityCheck >= 0){
        return 'paid'
      }
      else {
        return 'unpaid'
      }      
    }
  }
}
</script>


