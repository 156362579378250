<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-alert :value="alert.alert" :type="alert.type"> {{alert.message}} </v-alert>
                <v-alert :value="mismatch" type="error"> Warning!!! Some updates might be missing. Please clear your browser data. <br> On Chrome browser, open Settings -> History -> Clear browsing data -> Select "Cookies and site data" and "Cached images and files" -> Click clear data  </v-alert>
            </v-col>
        </v-row>
        <v-card class="ma-10 card-round" elevation="7"> 
            <v-row>
                <!-- <v-col md="4" xs="12" align="center">
                     <v-img max-height="100%" max-width="400" :src="require('../assets/logo_wtxt.png')"></v-img>
                </v-col> -->
                <v-col md="12" xs="12">
                     <v-row>
                        <v-col cols="12" justify="center" class="pa-7">
                            <h1 v-if="login" class="ml-5">Login</h1>
                            <h1 v-if="!login" class="ml-5">Register</h1>
                            <v-form v-if="login" class="ma-5"> 
                                <v-text-field color="primary" outlined v-model="id" prepend-inner-icon="mdi-account" label="Student ID"></v-text-field>
                                <v-text-field v-model="password" outlined prepend-inner-icon="mdi-lock" type="password" label="Password"></v-text-field>
                                <v-btn color="#e83c38" dark :loading="loginLoading" @click="checkDevice" class="mt-5">Login</v-btn> 
                                <v-btn text color="#01203f" @click="login = false" class="mt-5">Create an Account</v-btn>
                            </v-form>
                            <v-form v-if="!login" class="ma-5"> 
                                <v-text-field outlined v-model="id" prepend-inner-icon="mdi-account" @change="checkBranch" label="Student ID"></v-text-field>
                                <v-text-field outlined v-model="email" prepend-inner-icon="mdi-email" label="Email"></v-text-field>
                                <v-text-field outlined v-model="password" prepend-inner-icon="mdi-lock" type="password" label="Password"></v-text-field>
                                <v-text-field outlined v-model="rpassword" prepend-inner-icon="mdi-lock-outline" type="password" label="Repeat Password"></v-text-field>
                                <v-btn color="#e83c38" dark :loading="loading" :disabled="disabled" @click="register" class="mt-5">Register</v-btn> 
                                <v-btn text color="#01203f" @click="login = true" class="mt-5">Go to login</v-btn>
                            </v-form>
                        </v-col>
                        <v-col cols="12" class="pl-9">
                            <v-btn text @click="dialog = true" color="teal">Reset password</v-btn>
                        </v-col>
                        <v-col cols="12">
                            <v-img height="40" class="text-right" contain :src="require('../assets/nrlogo_tr.png')"></v-img>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-dialog v-model="dialog" max-width="400px">
                    <v-card>
                        <v-container>
                            <v-row justify="center">
                                <v-col cols="12">
                                    <v-text-field label="Registered email" v-model="email"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn color="#01203f" dark @click="resetPassword">Send reset link</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-dialog>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
export default {
    data: () => ({
        id: '',
        dialog: '',
        email: '',
        password: '',
        items: ['Trichy', 'Chennai', 'Mayiladuthurai', 'Madurai', 'Jayankondam', 'Ariyalur', 'Namakkal', 'Kumbakonam', 'Chidambaram', 'Course'],
        branch: '',
        rpassword: '',
        loading: false,
        loginLoading: false,
        alert: {alert: false, type: '', message: ''},
        login: true
    }),
    methods: {
        resetPassword () {
            firebase.auth().sendPasswordResetEmail(this.email)
            .then(() => {
                this.alert.alert = true
                this.alert.type = 'success'
                this.alert.message = 'Password reset link sent to '+this.email
                this.dialog = false
            })
            .catch((error) => {
                this.alert.alert = true
                this.alert.type = 'error'
                this.alert.message = error.message
            })
        },

        checkDevice() {
            // var uuid = this.$store.getters.loadedUUID
            // firebase.database().ref('uuids/'+this.id).once('value')
            // .then((data) => {
            //     if(data.val() && data.val().uuid != '') {
            //         var regUUID = data.val().uuid
            //         regUUID == uuid ?  this.logIn() :  alert('ID registered with some other device!')
            //     }
            //     else {
                    this.logIn()
            //     }
            // })
        },

        logIn () {
            this.loginLoading = true
            firebase.database().ref('students/'+this.id).once('value')
            .then((data) => {
                var dat = data.val()
                var email = dat.email
                if(!dat.active){
                    this.loginLoading = false
                    this.alert.alert = true
                    this.alert.type = 'error'
                    this.alert.message = 'Your account has been blocked! Please contact management.'
                    this.loading = false
                }
                else {
                    if(email){
                        firebase.auth().signInWithEmailAndPassword(email, this.password)
                        .then((res) => {
                            let user = res.user
                            if(user) {
                                this.$store.commit('SET_LOGGED_IN', true)
                                var sid = user.displayName
                                var branch = sid.charAt(0) + sid.charAt(1)
                                this.$store.dispatch("checkUUID", sid)
                                this.$store.dispatch("setStatus", sid)
                                this.$store.commit('setBranch', branch)
                                this.$store.commit('setRoll', sid)
                                this.$store.dispatch('checkPayment', sid)
                                this.$store.dispatch('checkTestPayment', sid)
                                this.$store.dispatch('checkDOJ', sid)
                                firebase.database().ref('students/'+sid).once('value')
                                .then((data) => {
                                this.$store.commit('setUserData', data.val())
                                })
                            }
                            else {
                                this.$store.commit('SET_LOGGED_IN', false)
                            }
                        })
                        .catch((error) => {
                            this.loginLoading = false
                            this.alert.alert = true
                            this.alert.type = 'error'
                            this.alert.message = error.message
                            this.loading = false
                        })
                        this.loginLoading = false
                    }
                    else {
                        this.loginLoading = false
                        this.alert.alert = true
                        this.alert.type = 'error'
                        this.alert.message = 'Invalid or unregistered Student ID!'
                        this.loading = false
                    }
                }
            })
        },

        checkBranch () {
            if(this.id.charAt(0) === 'H' && this.id.charAt(1) === 'O') {
                this.branch = 'Trichy'
                this.setBranch()
            }
            if(this.id.charAt(0) === 'C' && this.id.charAt(1) === 'H') {
                this.branch = 'Chennai'
                this.setBranch()
            }
            if(this.id.charAt(0) === 'M' && this.id.charAt(1) === 'Y') {
                this.branch = 'Mayiladuthurai'
                this.setBranch()
            }
            if(this.id.charAt(0) === 'M' && this.id.charAt(1) === 'A') {
                this.branch = 'Madurai'
                this.setBranch()
            }
            if(this.id.charAt(0) === 'A' && this.id.charAt(1) === 'R') {
                this.branch = 'Ariyalur'
                this.setBranch()
            }
            if(this.id.charAt(0) === 'J' && this.id.charAt(1) === 'A') {
                this.branch = 'Jayankondam'
                this.setBranch()
            }
            if(this.id.charAt(0) === 'N' && this.id.charAt(1) === 'A') {
                this.branch = 'Namakkal'
                this.setBranch()
            }
            if(this.id.charAt(0) === 'K' && this.id.charAt(1) === 'U') {
                this.branch = 'Kumbakonam'
                this.setBranch()
            }
            if(this.id.charAt(0) === 'C' && this.id.charAt(1) === 'I') {
                this.branch = 'Chidambaram'
                this.setBranch()
            }
            if(this.id.charAt(0) === 'T' && this.id.charAt(1) === 'H') {
                this.branch = 'Thuraiur'
                this.setBranch()
            }
            if(this.id.charAt(0) === 'S' && this.id.charAt(1) === 'I') {
                this.branch = 'Course'
                this.setBranch()
            }
            if(this.id.charAt(0) === 'T' && this.id.charAt(1) === 'B') {
                this.branch = 'TestBatch'
                this.setBranch()
            }
        },

        register () {
            this.loading = true
            firebase.database().ref('students/'+this.id).once('value')
            .then((data) => {
                var dat = data.val()
                if(dat) {
                    if(dat.uid != '') {
                        this.alert.alert = true
                        this.alert.type = 'error'
                        this.alert.message = 'ID already registered'
                        this.loading = false
                    }
                    else {
                        firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
                        .then((snap) => {
                            firebase.auth().currentUser.updateProfile({displayName: this.id})
                            firebase.database().ref('students/'+this.id).update({
                                name: dat.name,
                                phone: dat.phone,
                                active: true,
                                email: this.email,
                                uid: snap.user.uid
                            })
                            .then(() => {
                                this.alert.alert = true
                                this.alert.type = 'success'
                                this.alert.message = 'ID successfully registered'
                                this.loading = false
                            })
                        })
                        .catch((err) => {
                            this.alert.alert = true
                            this.alert.type = 'error'
                            this.alert.message = err.message
                            this.loading = false
                        })
                    }
                }
            })
        }
    },

    computed: {
        branchID () {
            return this.$store.getters.loadedBranch
        },
        mismatch () {
            return this.$store.getters.isVersionMismatch
        },
        disabled () {
            if(this.id && this.email && this.password != '' && this.password === this.rpassword) {
                return false
            }
            else {
                return true
            }
        },
        version () {
            return this.$store.getters.loadedVersion
        }
    }
}
</script>

<style scoped>
/* .wid-btn {
 min-width: 200px !important;
} */
.card-round
{
    border-radius: 1px !important;
}
.mt {
    margin-top: 10px !important;
}
.bg-p {
    background-position: right !important;
}
</style>