<template>
    <div>
        <v-navigation-drawer v-model="drawer" color="#cedcaa" app clipped>
            <v-list dense nav>
                <!-- <v-list-item
                v-for="item in items"
                :key="item.title"
                link
                :to="item.link">
                    <v-list-item-icon>
                        <v-icon> {{item.icon}} </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title> {{item.title}} </v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->
                <!-- <v-list-item v-if="branch != 'TB'" to="/">
                    <v-list-item-icon><v-icon>mdi-bell-ring</v-icon></v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title> Notifications </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="/test">
                    <v-list-item-icon><v-icon>mdi-file-document-edit-outline</v-icon></v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title> Test </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="branch != 'TB'" to="/question">
                    <v-list-item-icon><v-icon>mdi-book-open</v-icon></v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title> Questions </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="branch != 'TB'" to="/material">
                    <v-list-item-icon><v-icon>mdi-book-open-variant</v-icon></v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title> Materials </v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->
                <v-list-item v-if="branch != 'TB' && branch != 'AO' && branch != 'SI'" to="/live">
                    <v-list-item-icon><v-icon>mdi-video</v-icon></v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title> Live </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="branch === 'SI'" to="/silive">
                    <v-list-item-icon><v-icon>mdi-video</v-icon></v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title> SI Class </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="branch === 'AO'" to="/aolive">
                    <v-list-item-icon><v-icon>mdi-video</v-icon></v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title> TET </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="branch === 'T1'" to="/t1live">
                    <v-list-item-icon><v-icon>mdi-video</v-icon></v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title> TET P1 </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="branch === 'T2'" to="/t2live">
                    <v-list-item-icon><v-icon>mdi-video</v-icon></v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title> TET P2 </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="branch === 'SO'" to="/solive">
                    <v-list-item-icon><v-icon>mdi-video</v-icon></v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title> SO Class</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-btn text>Version {{version}}</v-btn>
            <v-img class="powered ml-3" contain :src="require('../assets/white.png')"></v-img>
        </v-navigation-drawer>
        <v-app-bar clipped-left app color="#cedcaa"> 
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <!-- <v-img max-height="50" max-width="50" contain :src="require('../assets/logo_nr.png')"></v-img> -->
            <v-toolbar-title>NR Videos</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn @click="logout" light color="#5cdb95">Logout</v-btn>
        </v-app-bar>
        <v-content>
            <v-alert :value="alert.alert" :type="alert.type">{{alert.message}}</v-alert>
            <v-alert :value="mismatch" type="error"> Warning!!! Some updates might be missing. Please clear your browser data. <br> On Chrome browser, open Settings -> History -> Clear browsing data -> Select "Cookies and site data" and "Cached images and files" -> Click clear data  </v-alert>
            <router-view></router-view>
        </v-content>
        <v-footer>
        
        </v-footer>
    </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
export default {
    name: 'App',
    data: () => ({
        drawer: true,
        items: [
            {title: 'Notifications', icon: 'mdi-bell-ring', link: '/notifications'},
            {title: 'Test', icon: 'mdi-file-document-edit-outline', link: '/test'},
            {title: 'Questions', icon: 'mdi-book-open', link: '/question'},
            {title: 'Materials', icon: 'mdi-book-open-variant', link: '/material'},
            {title: 'Live', icon: 'mdi-video', link: '/live'},
        ]
    }),
    methods: {
        logout () {
            var roll = this.$store.getters.loadedRoll
            firebase.auth().signOut()
            .then( () => this.$store.commit('SET_LOGGED_IN', false) )
            firebase.database().ref('users/'+roll).update({online: false, last_seen: firebase.database.ServerValue.TIMESTAMP})
        }
    },
    computed: {
        branch () {
            return this.$store.getters.loadedBranch
        },
        alert () {
            return this.$store.getters.loadedAlert
        },
        mismatch () {
            return this.$store.getters.isVersionMismatch
        },
        version () {
            return this.$store.getters.loadedVersion
        }
    }
}
</script>
<style scoped>
.powered {
    position: absolute;
    bottom: 0;
}
.bg-blue {
    background: #01203f !important;
    color:white;
}
.green {
    background-color: #1edd25 !important;
}
  .button {
    color: #1e4368;
    font-size: 17%;
    padding: 5;
    border-radius: 15;
    border-color: #5CDB95;
    width: 90%;
    background-color: #d4f5e2;
    font-weight:bold;
    border-width: 2%; 
 }
 .t-button {
  color: white;
  font-size: 17%;
  padding: 5;
  border-radius: 15;
  background: #5CDB95;
  font-weight:bold;
  border-width: 2%; 
}
</style>