<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <h2>Please complete your payment to avail this service!</h2>
            </v-col>
            <v-col cols="12">
                <v-btn color="green" :loading="loading" @click="payNow">Pay now</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data: () => ({ 
        loading: false
    }),

    computed: {
        buyer_name () {
        return this.$store.getters.loadedRoll
        },
        email () {
        return this.$store.getters.loadedUserData.email
        },
        phone () {
        return this.$store.getters.loadedUserData.phone
        },
    },

    methods: {
        payNow () {
            this.loading=true
            window.location.replace(`https://tharainfotech.com/payments?id=${this.buyer_name}&amount=3000`);
        }       
    }
}
</script>