<template>
  <div></div>
</template>

<script>
//import HelloWorld from '../components/HelloWorld';

export default {
  // components: {
  //   HelloWorld,
  // },
};
</script>
