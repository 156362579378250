import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase/app'
import 'firebase/messaging'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'


Vue.config.productionTip = false

new Vue({
  router,
  store,
  firebase,
  vuetify,
  render: h => h(App),

  created () {
    var firebaseConfig = {
      apiKey: "AIzaSyD2D4nmltGvvoHk54S2onOFGMWj9hjLNZI",
      authDomain: "nrtest-5fa75.firebaseapp.com",
      databaseURL: "https://nrtest-5fa75-default-rtdb.asia-southeast1.firebasedatabase.app",
      projectId: "nrtest-5fa75",
      storageBucket: "nrtest-5fa75.appspot.com",
      messagingSenderId: "246626822610",
      appId: "1:246626822610:web:786e68c9582677f8b3400b"
    }
    firebase.initializeApp(firebaseConfig)

    store.dispatch("setUUID")

    const messaging = firebase.messaging()

    messaging.usePublicVapidKey("BFytJkX53tRBHQyyyATBUEvgcIALmZbMWrTIO0Ru6lU1QXqhkOHvIKGKnRpFOmEPhLXuj_0F2FBy4_YpQqeR96c")

    // Request Permission of Notifications
    messaging.requestPermission().then(() => {

      // Get Token
      messaging.getToken().then((token) => {
        store.dispatch("setFireToken", token)
      })
    })
    
    // firebase.auth().onAuthStateChanged(user => {
    //   if(user) {
    //     store.commit('SET_LOGGED_IN', true)
    //     var sid = user.displayName
    //     var branch = sid.charAt(0) + sid.charAt(1)
    //     store.dispatch("setStatus", sid)
    //     store.commit('setBranch', branch)
    //     store.commit('setRoll', sid)
    //     store.dispatch('checkPayment', sid)
    //     store.dispatch('checkDOJ', sid)
    //     firebase.database().ref('students/'+sid).once('value')
    //     .then((data) => {
    //       store.commit('setUserData', data.val())
    //     })
    //   }
    //   else {
    //     store.commit('SET_LOGGED_IN', false)
    //   }
    // })
    store.dispatch('checkVersion')
  },
  // destroyed () {
  //   var roll = store.getters.loadedRoll
  //   firebase.database().ref('payment/'+roll).update({loggedin: false})
  // } 

}).$mount('#app')
